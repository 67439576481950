<template>
  <div style="position: relative; height: 100%; width: 100%;">
    <RecordingsPage
      v-on:load-start="imageLoading = true"
      v-on:load-end="imageLoading = false"
      v-on:update-start="updateStartImageEvent"
      v-on:update-end="updateEndImageEvent"
      :singleRecording="false"
    >
      <template v-slot:image>
        <div style="position: relative; height: 100%; width: 100%">
          <div
            style="position: absolute; width: 100%; width: 100%; z-index: 10"
          >
            <ImageCompareSlider
              :sliderLineWidth="2"
              :leftImage="imageStartUrl"
              :rightImage="imageEndUrl"
              :switch="false"
              v-on:rendered="loadedImageEvent"
            />
          </div>

          <v-overlay :value="loading" absolute style="z-index: 50 !important">
            <v-progress-circular
              v-if="loading"
              indeterminate
              width="3"
              size="40"
              color="primary"
              class="center"
            ></v-progress-circular>
          </v-overlay>
        </div>
      </template>
    </RecordingsPage>
  </div>
</template>

<script>
import RecordingsPage from '@/components/tools/project/recordings/RecordingsPageTemplate.vue';
// import ImageZoom from "@components/image/ImageZoom.vue";
import ImageCompareSlider from '@components/image/ImageCompareSlider.vue';

export default {
  name: 'ProjectViewCompareSlider',
  components: {
    RecordingsPage,
    // ImageZoom,
    ImageCompareSlider,
  },

  data() {
    return {
      loadedTimelapse: false,
      zoomScale: 2.5,
      imageLoading: false,
      imageLoaded: false,
      imageEndUrl: null,
      imageStartUrl: null,
      loadedTimeout: null,
    };
  },

  computed: {
    loading() {
      return !(!this.imageLoading && this.imageLoaded);
    },
  },

  methods: {
    updateStartImageEvent(imageUrlHandle) {
      this.imageLoaded = false;
      this.imageStartUrl = `${imageUrlHandle}&lastmod=${+new Date()}`;

      this.imageLoading = false;
      this.loadedTimeout = setTimeout(() => {
        this.imageLoaded = true;
      }, 5000);
    },

    updateEndImageEvent(imageUrlHandle) {
      this.imageLoaded = false;
      this.imageEndUrl = `${imageUrlHandle}&lastmod=${+new Date()}`;

      this.imageLoading = false;
      this.loadedTimeout = setTimeout(() => {
        this.imageLoaded = true;
      }, 5000);
    },

    loadedImageEvent() {
      this.imageLoaded = true;
      clearTimeout(this.loadedTimeout);
    },
  },
};
</script>

<style>
.clipped-image-2 {
  width: 50%;
  -webkit-clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
}
.clipped-image-1 {
  width: 50%;
  left: -50%;
  -webkit-clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
}
.video-player {
  height: 100%;
  width: 100%;
}
</style>
